<template>
  <page-layout :ready="!loading">
    <template slot="breadcrumb">
      <el-breadcrumb-item :to="{ name: 'priceListsManagement' }">
        {{ $t('routes.priceListsManagement') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="!!priceList">{{ priceList.name }}</el-breadcrumb-item>
    </template>
    <div class="d-flex justify-content-between mb-4">
      <h3 v-if="!!priceList">
        {{ priceList.name }}
      </h3>
      <div class="d-flex align-items-center">
        <el-checkbox v-model="compareSelected" />
        <p class="mx-2">{{ $t('price.priceList.showDateToCompare') }}</p>
        <el-date-picker
          v-model="compareDate"
          :disabled="!compareSelected"
          format="dd.MM.yyyy"
          :class="$t('direction') === 'ltr' ? 'me-4' : 'ms-4'"
          type="date"
          :clearable="false"
          :editable="false"
        />
        <Button
          type="text"
          :disabled="!compareSelected"
          class="text-typography-primary"
          :class="$t('direction') === 'ltr' ? 'me-4' : 'ms-4'"
          @click="incrementCompareDate"
        >
          <i class="el-icon-arrow-left" />
        </Button>
        <Button
          type="text"
          :disabled="!compareSelected"
          class="text-typography-primary"
          :class="$t('direction') === 'ltr' ? 'me-4' : 'ms-4'"
          @click="decrementCompareDate"
        >
          <i class="el-icon-arrow-right" />
        </Button>
        <Button
          class="mx-0"
          @click="() => createPriceListUpdate({ priceListId: priceList.id, businessId: supplierId })"
        >
          {{ $t('price.priceList.updatePriceList') }}
        </Button>
      </div>
    </div>
    <div>
      <Table border rounded :data="tableData" :columns="columns" show-index>
        <template #cell-category="{ rowData: { category } }">
          <span
            v-for="(section, index) in [...category]"
            :key="section"
            :class="index !== category.length - 1 ? 'text-typography-secondary' : ''"
          >
            <template v-if="index !== category.length - 1">{{ `${section} / ` }}</template>
            <template v-else>{{ section }}</template>
          </span>
        </template>
        <template
          #cell-currentPrice="{
            rowData: {
              currentPrice: { price },
              differenceInPercentBetweenPrices,
            },
          }"
        >
          <div class="d-flex align-items-center">
            <p :class="$t('direction') === 'ltr' ? 'me-2' : 'ms-2'">{{ formatMoney(price) }}</p>
            <PercentageChangeBadge
              v-if="compareSelected && differenceInPercentBetweenPrices"
              :value="differenceInPercentBetweenPrices"
            />
          </div>
        </template>
        <template #header-currentPrice="{ column: { header } }">
          <div>
            {{ header }}
            <small class="d-block text-muted">
              {{ $t('price.priceList.headers.current') }}
            </small>
          </div>
        </template>
        <template #cell-compareDatePrice="{ rowData }">
          <template v-if="rowData.compareDatePrice">
            <div class="d-flex align-items-center">
              <p :class="$t('direction') === 'ltr' ? 'me-2' : 'ms-2'">
                {{ formatMoney(rowData.compareDatePrice.price) }}
              </p>
              <template v-if="compareSelected">
                <Tag v-if="rowData.compareDatePrice.date === compareDate.getTime()" type="success">
                  {{ $t('price.priceList.headers.priceUpdated') }}
                </Tag>
              </template>
            </div>
          </template>
          <template v-else>-</template>
        </template>
      </Table>
    </div>
  </page-layout>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { flatten, isNil } from 'ramda';
import { useMutation } from '@vue/apollo-composable';

import i18n from '@/imports/startup/client/i18n';
import { currencyFloat } from '@/locale/numberConfig';
import { useProductsNew } from '@/modules/product/compositions';
import { Button, Table, Tag } from '@/modules/core/components';
import PercentageChangeBadge from '@/modules/purchase-management/components/PercentageChangeBadge';

import { usePriceList, CREATE_PRICE_LIST_UPDATE_MUTATION } from './compositions';

export const formatMoney = (number) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number / 100).toLocaleString(i18n.locale, currencyFloat)
    : null;
};

const TABLE_HEADERS = {
  CATEGORY: 'category',
  SKU: 'sku',
  NAME: 'name',
  CURRENT_PRICE: 'currentPrice',
  COMPARE_DATE_PRICE: 'compareDatePrice',
};

const getPriceSections = (priceList, priceId) => {
  if (!priceList?.sections) return [];
  return flatten(
    priceList.sections
      .map((section) => getPriceSectionsRecursive(priceId, section))
      .filter((sectionNames) => sectionNames.length)
  );
};

const getPriceSectionsRecursive = (priceId, section) => {
  const hasPriceId = section.priceIds?.includes(priceId);
  if (hasPriceId) return [section.name];

  const hasSections = !!section.sections?.length;

  return hasSections
    ? section.sections
        .map((currentSection) => {
          const sectionNameArray = flatten(getPriceSectionsRecursive(priceId, currentSection));
          return sectionNameArray.length ? [section.name, ...sectionNameArray] : [];
        })
        .filter((sections) => sections.length)
    : [];
};

const calcDifferenceInPercentage = (num1, num2) => ((num1 - num2) / num2) * 100;

const getActivePrice = (price, date) => {
  return [...price.data]
    .sort((priceData1, priceData2) => priceData2.date - priceData1.date)
    .find((priceData) => priceData.date <= date.getTime());
};

export default {
  components: { Button, Table, Tag, PercentageChangeBadge },
  setup() {
    const root = getCurrentInstance().proxy;
    const priceListId = computed(() => root.$route.params.priceListId);
    const supplierId = computed(() => root.$route.params.tenantId);

    const {
      mutate: createPriceListUpdate,
      loading: createPriceListUpdateLoading,
      onDone: createPriceListUpdateOnDone,
      onError: createPriceListUpdateOnError,
    } = useMutation(CREATE_PRICE_LIST_UPDATE_MUTATION);

    createPriceListUpdateOnDone((res) => {
      root.$router.push({
        name: 'priceListsManagement.priceListUpdate',
        params: { id: res.data.priceListUpdateCreate.id },
      });
    });
    createPriceListUpdateOnError(() => root.$message.error(root.$t('price.priceListUpdate.createFailedError')));

    const compareDate = ref(DateTime.utc().startOf('day').toJSDate());
    const compareSelected = ref(false);
    const currentDate = new Date();

    const { priceList, loading: priceListLoading } = usePriceList(priceListId);
    const { products, loading: productsLoading } = useProductsNew({ businessId: supplierId.value });

    const comparePrices = computed(() => {
      return compareSelected.value
        ? priceList.value.prices.map((price) => getActivePrice(price, compareDate.value))
        : [];
    });
    return {
      createPriceListUpdate,
      formatMoney,
      loading: computed(() => priceListLoading.value || productsLoading.value || createPriceListUpdateLoading.value),
      currentDate,
      compareDate,
      priceList,
      products,
      comparePrices,
      compareSelected,
      supplierId,
    };
  },
  computed: {
    columns() {
      const columns = [
        {
          header: this.$t('price.priceList.headers.categoryInPriceList'),
          key: TABLE_HEADERS.CATEGORY,
        },
        {
          header: this.$t('price.priceList.headers.sku'),
          key: TABLE_HEADERS.SKU,
        },
        {
          header: this.$t('price.priceList.headers.productName'),
          key: TABLE_HEADERS.NAME,
        },
        {
          header: DateTime.fromJSDate(this.currentDate).toFormat('dd.LL.yyyy'),
          key: TABLE_HEADERS.CURRENT_PRICE,
        },
        {
          header: DateTime.fromJSDate(this.compareDate).toFormat('dd.LL.yyyy'),
          key: TABLE_HEADERS.COMPARE_DATE_PRICE,
          customClass: 'bg-compare',
        },
      ];
      return columns.filter((column) => column.key !== TABLE_HEADERS.COMPARE_DATE_PRICE || this.compareSelected);
    },
    tableData() {
      if (this.loading) return [];

      return this.priceList?.prices.map((price, index) => {
        const priceProduct = this.products.find((product) => product.id === price.productId);
        const compareDatePrice = this.comparePrices[index];
        const currentPrice = getActivePrice(price, this.currentDate);
        const differenceInPercentBetweenPrices = !isNil(compareDatePrice)
          ? calcDifferenceInPercentage(currentPrice.price, compareDatePrice.price)
          : 0;

        return {
          ...price,
          name: priceProduct?.name ?? '-',
          sku: priceProduct?.sku ?? '-',
          currentPrice,
          compareDatePrice,
          differenceInPercentBetweenPrices,
          category: getPriceSections(this.priceList, price.id),
        };
      });
    },
  },
  methods: {
    incrementCompareDate() {
      this.compareDate = DateTime.fromJSDate(this.compareDate).plus({ days: 1 }).startOf('day').toJSDate();
    },
    decrementCompareDate() {
      this.compareDate = DateTime.fromJSDate(this.compareDate).minus({ days: 1 }).startOf('day').toJSDate();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .bg-compare {
  background: #f5f7fd;
}
</style>
