<template>
  <page-layout>
    <template #breadcrumb>
      <el-breadcrumb-item>{{ $t('routes.priceListsManagement') }}</el-breadcrumb-item>
    </template>

    <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="mb-7" />
    <PriceListsTable
      v-if="activeTab === 0"
      :price-lists="priceLists"
      :price-lists-loading="priceListsLoading"
      :refetch="refetchPriceList"
    />
    <PriceListUpdates v-else :price-lists="priceLists" />
  </page-layout>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { Tabs } from '@/modules/core/components';
import { useTenancy } from '@/modules/auth';

import PriceListUpdates from './components/PriceListUpdates';
import PriceListsTable from './components/PriceListsTable';
import { usePriceLists } from './compositions';

export default {
  components: { Tabs, PriceListUpdates, PriceListsTable },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();

    const {
      priceLists,
      loading: priceListsLoading,
      refetch: refetchPriceList,
    } = usePriceLists(computed(() => currentTenant.value.id));

    return {
      priceLists,
      priceListsLoading,
      refetchPriceList,
      activeTab: ref(0),
      tabs: [
        { text: root.$i18n.t('price.PriceListsManagement.priceLists') },
        { text: root.$i18n.t('price.PriceListsManagement.priceListUpdates') },
      ],
    };
  },
};
</script>
